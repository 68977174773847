.kleedleContainer {
  background-color: rgba(0, 0, 0, 0.6);
  height: calc(100vh - 58px);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.guessesContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.messageBox {
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
}
.message {
  height: 50px;
  text-align: center;
  padding-top: 10px;
}
