.pushDown {
  height: 50px;
}

.blackContainer {
  padding: 5%;
}

.poopButton {
  font-size: 50px;
}

.bottomMargin {
  margin-bottom: 16px !important;
}

.warning {
  color: red;
}

.button1 {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid white;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: white;
  text-align: center;
  transition: all 0.2s;
  background-color: rgb(248, 249, 250, 0);
}
.button1:disabled {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid gray;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: gray;
  text-align: center;
  transition: all 0.2s;
  background-color: lightgray;
}
.button1:hover {
  color: #000000;
  background-color: rgba(255, 255, 255, 0.9);
}
.button1:disabled:hover {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid gray;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: gray;
  text-align: center;
  transition: all 0.2s;
  background-color: lightgray;
}
