.text-right {
  text-align: right !important;
}

.hideScrollBars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  color: white;
  font-weight: bold;
}
/* 
.videoContainer {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

#myVideo {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;
} */

.videoContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.videoContainer > #myVideo {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media screen and (max-aspect-ratio: 1920/1080) {
  .videoContainer > #myVideo {
    height: 100%;
  }
}
@media screen and (min-aspect-ratio: 1920/1080) {
  .videoContainer > #myVideo {
    width: 100%;
  }
}
.myContainerBG {
  background-color: rgba(0, 0, 0, 0.6);
}

.myNavBar {
  background-color: black;
}

.whiteText {
  color: white !important;
}

.offWhiteText {
  color: rgb(200, 200, 200) !important;
}

.whiteText:hover {
  color: lightgray !important;
}

.noBG {
  background-color: rgba(0, 0, 0, 0);
}
/* 
.blackBG {
  background-color: rgb(0, 0, 0) !important;
} */

select,
input {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: white !important;
}
