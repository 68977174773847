.guessedLetter {
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  background-color: rgba(130, 130, 130, 0);
  font-size: xx-large;
  font-weight: bolder;
  color: white;
  margin: 3px;
}

.empty {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(150, 150, 150);
}

.guessInput {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(220, 220, 220);
}

.keyboardRow {
  width: 100%;
  display: flex;
  justify-content: center;
}

.keyboardLetter {
  height: 54px;
  width: 42px;
  line-height: 50px;
  text-align: center;
  background-color: rgb(130, 130, 130);
  color: white;
  margin: 3px;
  border-radius: 3px;
  font-size: medium;
  user-select: none;
}

.correct {
  background-color: rgb(100, 150, 100);
}

.contains {
  background-color: rgb(180, 160, 80);
}

.incorrect {
  background-color: rgb(50, 50, 50);
}

.wideKey {
  height: 50px;
  width: 65px;
  background-color: rgb(130, 130, 130);
  color: white;
  text-align: center;
  line-height: 50px;
  margin: 5px;
  border-radius: 3px;
  font-size: small;
  user-select: none;
}
